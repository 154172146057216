<script setup>
import LocationLayout from '@/Layouts/LocationLayout.vue';
import { inject } from 'vue';
import Shift from './Partials/Shift.vue';

import Pagination from '@/Components/Pagination/PaginationFE.vue';
import { useForm } from '@inertiajs/vue3';
const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    shifts: Object,
    query: Object,
    liked_flexer_ids: Object,
});

const form = useForm({
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('location.planning.todo.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['shifts', 'query'],
    });
};
</script>

<template>
    <LocationLayout title="Planning">
        <h1 class="mb-8 text-8 font-bold">{{ $t('Sign-ups') }}</h1>
        <div class="relative z-0 flex flex-col gap-8 xl:flex-row">
            <div class="grid gap-8 w-full divide-y">
                <template v-for="shift in shifts.data" :key="shift.id">
                    <Shift :shift="shift" :liked_flexer_ids="liked_flexer_ids" />
                </template>
                <template v-if="shifts.data.length === 0">
                    <div class="py-8 text-lg">
                        {{ $t('No {models} found', { models: $t('applications') }) }}
                    </div>
                </template>
            </div>
        </div>
        <Pagination :links="shifts.links" @setPage="getData" :pageData="shifts" />
    </LocationLayout>
</template>
